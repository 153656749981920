<template>
<div>
  <div class="container-fluid">
    <PageTitle noAdd />
    <template v-if="isList">
      <card-list 
        :filter.sync="filter"
        :dataListing.sync="data"
        :idKey="idKey"
        :fields="fields"

        :no-multi-select="true"
      > 
        <template #filters>
          <b-col md=2>
            <VSelect 
              v-model="filter.month" 
              placeholder="-- Pilih Bulan --"
              :options="bulan" 
              :reduce="v=>String(v.value)" 
              @input="doFilter"
            />
          </b-col>
          <b-col md=2>
            <VSelect 
            v-model="filter.year" 
            placeholder="-- Pilih Tahun --"
            :options="mrTahun" 
            :reduce="v=>String(v.value)" 
            @input="doFilter"
          />
          </b-col>
          <b-col md=2>
            <VSelect 
              v-model="filter.status" 
              placeholder="-- Semua Status --"
              :options="status" 
              :reduce="v=>v.value" 
              @input="doFilter"
            />
          </b-col>
        </template>
        <template #mr_status="data">
          <LabelStatus 
            :status="data.scope.item[statusKey]" 
            type="sasaranRtu" 
          />
        </template>
        <template #count="data">
          <ul>
            <li v-for="(value, index) in Object.keys(data.scope.value)" :key="index">
              {{ value }} : {{ data.scope.value[value] }}
            </li>
          </ul>
        </template>
      </card-list>
    </template>

  </div>
</div>
</template>

<script>
import GlobalVue from '@libs/Global'
import PageTitle from '@view/include/PageTitle'
import CardList from '@/components/CardList'
export default{
  extends:GlobalVue,
  components:{PageTitle, CardList},
  data(){
    return {
      idKey:'mr_id',
      statusKey:'mr_status',
      fields:[
        { key:'number', label:'#' },
        // { key:'mr_create_date', label: "Tahun", is_custom: true },
        { key:'mr_code', label:'Kode Risiko' },
        { key:'muk_description', label:'Unit Kerja', thClass: 'width_120' },
        { key:'mo_name', label:'Kantor', thClass: 'width_120' },
        { key:'bu_email', label:'Email' },
        { key:'mr_name', label:'Nama Risiko', thClass: 'width_200' },
        { key:'msr_description', label:'Sasaran RTU', thClass: 'width_200' },
        { key:'mr_muatan', label:'Muatan', thClass: 'width_120' },
        { key: 'count', label: 'Jumlah Data', is_custom: true, thClass: 'width_170' },
        { key:'mr_status', label:'Status', is_custom:true, thClass: 'width_120' },
      ],
      status: [],
      mrTahun: [],
      bulan: [
        {value: 1, label: "Januari"},
        {value: 2, label: "Februari"},
        {value: 3, label: "Maret"},
        {value: 4, label: "April"},
        {value: 5, label: "Mei"},
        {value: 6, label: "Juni"},
        {value: 7, label: "Juli"},
        {value: 8, label: "Agustus"},
        {value: 9, label: "September"},
        {value: 10, label: "Oktober"},
        {value: 11, label: "November"},
        {value: 12, label: "Desember"},
      ]
    }
  },
  mounted(){
    this.apiGet({query: {order: 'newest'}})
  },
  watch:{
    $route(){
      this.apiGet({query: {order: 'newest'}})
    },
  },
}
</script>